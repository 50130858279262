import request from '@/util/request'
export function getSimCard(imei) {
    return request({
        url: '/simcard/query/v2',
        method: 'post',
        data:{imei}
    })
}
export function getRealNameUrl(imei) {
    return request({
        url: '/simcard/realNameUrl/' + imei,
        method: 'get',
    })
}
